$btn-color: rgb(51, 108, 199);
$btn-font-color: white;

.btnn-btnn {
  b {
    padding: 0px 65px;
    letter-spacing: 1px;
  }
}

// Assessment-body section

.Assessment-body {
  top: 0;
  right: 0;
  overflow-y: hidden;
}

//Assessment question
.assessment-question {
  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  h6 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  pre {
    border-radius: 10px;
    line-height: 15px;
    padding: 15px !important;
  }
}
